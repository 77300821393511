<template>
    <div class="ipbody ipbody-Style-of-activity pay-box w1260">
        <h1 style="max-width: 1000px;">
            <!-- <img src="https://wapi.piaproxy.com/static/index/img/pay/h1.svg" alt="img"> -->
            <!-- <div>Choose the right plan<br/> that fits your <span>business.</span></div> -->
            <div>
                <div class="h1-b">PIA Special Sale</div>
                Save <span>61% , $198</span> OFF for 1200IPs Plan
            </div>
            <!-- <div class="djs_banner">
               <p class="djs_banner_tit">PiaProxy Christmas Offer:</p>
               <div class="kuang-box">
                   <div class="kuang">
                       <p class="dount_D0">00</p>
                       <span>Day</span>
                   </div>:
                   <div class="kuang">
                       <p class="dount_H0">00</p>
                       <span>Time</span>
                   </div>:
                   <div class="kuang">
                       <p class="dount_M0">00</p>
                       <span>Min</span>
                   </div>
               </div>

           </div> -->

            <div class="h1img1 huodonghas_yes"></div>
            <div class="h1img2 huodonghas_yes"></div>
            <!-- <img class="h1img4 huodonghas_yes" src="https://wapi.piaproxy.com/static/index/img/pay/ico_payh1_4.svg"> -->
            <div class="h1img3 huodonghas_yes">
                <p>61% OFF</p>
            </div>
        </h1>
        <div class="htxt lang_cont_h_txt_ip huodonghas_no" style="display: none;">Our flexible plans and add-ons are designed to grow with your business. Contact us for help choosing or to get a custom quote.</div>
        <!--Offer count down-->
        <div class="h-countdown huodonghas_yes">
            <div class="txt">
                <p>Offer Ends In</p>
                <div class="offerends"></div>
            </div>
            <div class="kuang kuang1">
                <p class="dount_D">00</p>
                <span>DAYS</span>
            </div>
            <div class="kuang kuang2">
                <p class="dount_H">00</p>
                <span>Hours</span>
            </div>
            <div class="kuang kuang3">
                <p class="dount_M">00</p>
                <span>Minutes</span>
            </div>
            <div class="kuang kuang4">
                <p class="dount_S">00</p>
                <span>Seconds</span>
            </div>
        </div>

        <div class="d-flex flex-ai-center flex-jc-center">
            <div class="paylist-nav">
                <div
                    v-for="(plan, index) in plans"
                    class="nav-li"
                    :class="{'active': Object.keys(selectedPlan).length && selectedPlan.id === plan.id}"
                    @click="selectPlan(plan)"
                >
                    <img v-if="index==0" src="/images/svg/recommend.svg" style="margin-right: 5px;" alt="img">
                    <span>{{ plan.title }}</span>
                    <div v-if="plan.description != ''" class="pro">
                        <div class="cont lang_paynav1" v-html="plan.description"></div>
                    </div>
                    <div class="paynav-mark">${{plan.price_data.final_price_per_ip}}/IP</div>
                    <div v-if="Object.keys(plan.price_data).length > 0 && index > 0" class="navli-jb">{{ plan.price_data.discount }}% OFF, SAVE ${{ plan.price_data.discount_amount }}</div>
                </div>
                <div class="nav-li" style="display: none;"></div>
            </div>
        </div>
        <div v-if="Object.keys(selectedPlan).length"
             class="pay-list payList"
             :class="selectedPlan.plan_type=='enterprise' ? 'pay_list_paynav5' : ''"
        >
            <!--If plan type is enterprise then change the layout-->
            <div v-if="selectedPlan.plan_type=='enterprise'" class="pay-list5-box">
                <div class="pay-txt">
                    <div class="tit">PIA Enterprise <span class="tit-sign">Exclusive</span> Service</div>
                    <div class="ftit">Earn great deals and exclusive services</div>
                    <div class="t-ul">
                        <div class="li">
                            <img src="https://wapi.piaproxy.com/static/index/img/pay/icon_click.svg" alt="icon">
                            <p>Unlimited sub-accounts</p>
                        </div>
                        <div class="li">
                            <img src="https://wapi.piaproxy.com/static/index/img/pay/icon_click.svg" alt="icon">
                            <p>No handling fee</p>
                        </div>
                        <div class="li">
                            <img src="https://wapi.piaproxy.com/static/index/img/pay/icon_click.svg" alt="icon">
                            <p>Exclusive lowest discount</p>
                        </div>
                        <div class="li">
                            <img src="https://wapi.piaproxy.com/static/index/img/pay/icon_click.svg" alt="icon">
                            <p>Proxy type is Popular Plan</p>
                        </div>
                    </div>
                </div>
                <div class="li-cont-box payLiCont">
                    <div
                        v-for="(proxyPackage, index) in selectedPlan.packages"
                        class="li liType-normal"
                        :class="{'istop': proxyPackage.is_default == 1, 'active' : selectedPackage.id === proxyPackage.id}"
                        @click="selectPackage(proxyPackage)"
                    >
                        <div v-if="proxyPackage.is_default == 1 && proxyPackage.discount > 0" class="istop-discount">
                            <span class="proxy-discount">{{ proxyPackage.discount.replace(".00", "") }}</span>
                        </div>
                        <div class="li-cont">
                            <div class="name">{{ proxyPackage.total_ips }} IPs</div>
                            <template v-if="proxyPackage.is_default==1">
                                <div class="pic">
                                    <span class="span1">$</span>
                                    <span class="span2">0</span>
                                    <span class="span3">{{ proxyPackage.final_price_per_ip }}</span>
                                    <span class="span4">/IP</span>
                                </div>
                                <div class="total">
                                    <span>{{ proxyPackage.price_per_ip }}/IP</span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="pic">
                                    ${{ proxyPackage.final_price_per_ip }}
                                    <span>/IP</span>
                                </div>
                                <div class="total">
                                    <span>{{ proxyPackage.price_per_ip }}/IP</span>
                                </div>
                            </template>
                            <div class="pay-li-fot">
                                <div class="check">
                                    <div class="quan"></div>
                                </div>
                                <div class="f-txt txt1">
                                    <span><span class="lang_total">Total</span>:</span>
                                    <span class="rtxt">${{ proxyPackage.final_price }}</span>
                                    <span v-if="proxyPackage.price != proxyPackage.final_price" class="dtxt">${{ proxyPackage.price }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                v-for="(proxyPackage, index) in selectedPlan.packages"
                class="li liType-normal"
                :class="{'stand_out': proxyPackage.is_default == 1, 'active' : selectedPackage.id === proxyPackage.id}"
                @click="selectPackage(proxyPackage)"
            >
                <div v-if="(proxyPackage.is_default == 1 && proxyPackage.discount > 0) || proxyPackage.discount == '50.00'" class="pay-ipsave">
                    <span v-if="proxyPackage.is_default == 1" class="proxy-discount">{{ proxyPackage.discount.replace(".00", "") }}</span>
                    <span v-else>{{ proxyPackage.discount.replace(".00", "") }}% OFF</span>
                </div>
                <div class="li-cont">
                    <div class="pay-name-box">
                        <div class="pay-name">
                            {{ proxyPackage.total_ips }}
                            <span v-if="proxyPackage.bonus_ips >0">+{{ proxyPackage.bonus_ips }}</span>
                            IPs
                        </div>
                    </div>
                    <template v-if="proxyPackage.is_default==1">
                        <div class="pay-mony-box">
                            <div class="pay-mony">
                                <!-- <p><span>$0.24</span>/IP</p> -->
                                <p class="y-unit">
                                    <span class="span1">$</span>
                                    <span class="span2">0</span>
                                    <span class="span3">{{ proxyPackage.final_price_per_ip }}</span>
                                    <span class="span4">/IP</span>
                                </p>
                                <p class="n-unit">
                                    <span class="span1">$</span>
                                    <span class="span2">0</span>
                                    <span class="span3">{{ proxyPackage.final_price_per_ip }}</span>
                                    <span class="span4">/IP</span>
                                </p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="pay-mony-box">
                            <div class="pay-mony">
                                <!-- <p><span>$0.24</span>/IP</p> -->
                                <p class="y-unit"><span>${{ proxyPackage.final_price_per_ip }}</span>/IP</p>
                                <p class="n-unit"><span>${{ proxyPackage.final_price_per_ip }}</span>/IP</p>
                            </div>
                        </div>
                    </template>
                    <div v-if="proxyPackage.price_per_ip != proxyPackage.final_price_per_ip" class="pay-orginprc">${{ proxyPackage.price_per_ip }}/IP</div>
                    <div class="pay-li-fot">
                        <div class="check">
                            <div class="quan"></div>
                        </div>
                        <div class="f-txt txt1">
                            <span><span class="lang_total">Total</span>:</span>
                            <span class="rtxt">${{ proxyPackage.final_price }}</span>
                            <span v-if="proxyPackage.price != proxyPackage.final_price" class="dtxt">${{ proxyPackage.price }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pay-best">
            <div class="ul">
                <div class="li">
                    <img src="/images/png/best_ip1.png" width="40" alt="best1">
                    <p class="lang_paybest_ip1">180+ countries around the world</p>
                </div>
                <div class="li">
                    <img src="/images/png/best_ip2.png" width="40" alt="best1">
                    <p class="lang_paybest_ip2">150M+ residential proxy</p>
                </div>
                <div class="li">
                    <img src="/images/png/best_ip3.png" width="40" alt="best1">
                    <p class="lang_paybest_ip3">Using 1 proxy costs 1 proxy balance</p>
                </div>
                <div class="li">
                    <img src="/images/png/best_ip4.png" width="40" alt="best1">
                    <p class="lang_paybest_ip4">Proxies balance is valid for life</p>
                </div>
                <div class="li">
                    <img src="/images/png/best_ip5.png" width="40" alt="best1">
                    <p class="lang_paybest_ip5">Support for developer APIs</p>
                </div>
                <div class="li">
                    <img src="/images/png/best_ip6.png" width="40" alt="best1">
                    <p class="lang_paybest_ip6">Highest anonymity Socks5, Http(s)</p>
                </div>
            </div>
            <div class="best-pay">
                <div class="bmoy-box">
                    <div class="org-moy ips giftIps" style="">
                        <!-- <i name="giftipval" ></i> -->
                    </div>
                    <div class="b-moy">
                        <span class="lang_needpay">You need pay：</span>
                        <b>$<i name="package_money_nFee">{{ selectedPackage.final_price }}</i> </b>

                        <div v-if="selectedPackage.discount > 0" class="giftIps">
                            <i name="giftipval" class="original_price" style="text-decoration: line-through;">${{ selectedPackage.price }}</i>
                        </div>
                    </div>
                </div>

                <!-- order_info -->
                <div class="order_info">
                    <a href="javascript:;">Order Details</a>

                    <div class="order_content">
                        <!-- tit -->
                        <div class="order_tit_cot">
                            <div class="order_tit">Order total:</div>
                            <div class="order_tit_price">
                                <!-- <span>$</span><i name="package_money">0.00</i> -->
                                <span>$</span><i name="package_money_nFee">{{ selectedPackage.final_price }}</i>

                            </div>
                        </div>

                        <!-- cont -->
                        <div v-if="selectedPackage.discount > 0" class="order_cont">
                            <div class="order_li discounSavehas" style="">
                                <div class="order_li_name"><span class="lang_paydiscount">Discount:</span> <i name="discoun_save">{{ selectedPackage.discount.replace(".00", "") }}%</i></div>
                                <div class="order_li_price">
                                    <!-- <i name="couponCont_txt"></i> -->
                                    -<span>$<i name="difference_money">{{ selectedPackage.discount_amount }}</i></span>
                                </div>
                            </div>
                        </div>
                        <!-- cont -->
                    </div>
                </div>
                <!--  -->
                <!-- <div class="aman-btn go-order lang_baynow"></div> -->
                <a href="/checkout" class="aman-btn lang_baynow">Buy Now</a>
                <div class="payment-icon-container">
                    <img src="/images/svg/paytype_TRX26.svg" class="payment-con" alt="TRX">
                    <img src="/images/png/paytype_virtual1.png" class="payment-con" alt="BTC">
                    <img src="/images/png/paytype_virtual10.png" class="payment-con" alt="USDT">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            plans           : [],
            selectedPlan    : {},
            selectedPackage : {},
            discountPercent : 0,
            discountAmount  : 0,
        }
    },
    created() {
        this.getPlans();
    },
    mounted() {

    },
    methods: {
        getPlans() {
            let self = this;
            axios.get('/proxy-plans')
                .then(response => {
                    this.plans = response.data.plans;
                    if (this.plans.length > 0) {
                        this.selectedPlan = this.plans[0];
                        self.getDefaultPackage();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },

        // Get default package for the selected plan
        getDefaultPackage() {
            let self = this;
            if (this.selectedPlan.packages.length > 0) {
                this.selectedPlan.packages.forEach((item, index) => {
                    if (item.is_default == 1) {
                        this.selectedPackage = item;
                    }
                });
            }
        },

        // Change plan
        selectPlan(item) {
            this.selectedPlan = item;
            this.getDefaultPackage();
        },
        // Select proxy package
        selectPackage(item) {
            this.selectedPackage = item;
        },
    },
    watch: {
        // Watch selected package
        selectedPackage:  {
            handler(newVal, oldVal) {
                //console.log("Nested property changed:", newVal, oldVal);
            },
            deep: true
        },
    }

}
</script>

<style scoped>

</style>
