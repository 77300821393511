import { render, staticRenderFns } from "./ProxyCheckout.vue?vue&type=template&id=64b158b2&scoped=true&"
import script from "./ProxyCheckout.vue?vue&type=script&lang=js&"
export * from "./ProxyCheckout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b158b2",
  null
  
)

export default component.exports